<template>

  <!-- Section Form -->
  <j-form model="sections.section" class="section-form">

    <!-- Slug (read-only) -->
    <j-control
      name="slug"
      label="Slug"
      disable
    />

    <!-- Tagline -->
    <!-- <j-control name="tagline" /> -->

    <!-- Description -->
    <j-control
      name="description"
      rows="10"
    />

    <!-- Badge -->
    <j-control
      name="badge_src"
      aspect-ratio="1"
      cols="6"
    />

    <v-col cols="6" class="py-0">
      <v-row>

        <!-- Highlight Color -->
        <j-control
          name="highlight_color"
        />

        <!-- Background Color -->
        <j-control
          name="background_color"
        />

      </v-row>
    </v-col>
  </j-form>
</template>

<script>

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState } = createNamespacedHelpers('sections')

export default {
  name: 'SectionEdit',
  // -----------
  // Computed ==
  // -----------
  computed: {
    // Vuex
    ...mapState([
      'section'
    ])
  }
}
</script>
