<template>

  <!-- Section Edit Panel -->
  <v-tabs
    v-if="canEditSection"
    v-model="tabs"
    fixed-tabs
    id="section-edit-panel"
  >

    <!-- Section Tab -->
    <j-tab
      href="#edit"
      icon="file"
    >
      Section
    </j-tab>

    <!-- Images Tab -->
    <j-tab
      href="#images"
      icon="images"
      :count="section.images.length"
    >
      Images
    </j-tab>

    <!-- Section Form -->
    <v-tab-item
      v-if="canEditSection"
      value="edit"
    >
      <SectionForm />
    </v-tab-item>

    <!-- Image Manager -->
    <v-tab-item
      v-if="canEditSection"
      value="images"
    >
      <MediaManager :source="section" />
    </v-tab-item>

  </v-tabs>
</template>

<script>

// Components
import MediaManager from '@/components/Media/MediaManager'
import SectionForm from '@/components/Sections/SectionForm'

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState } = createNamespacedHelpers('sections')

export default {
  name: 'SectionEditPanel',
  // -------------
  // Components ==
  // -------------
  components: {
    MediaManager,
    SectionForm
  },
  // -------
  // Data ==
  // -------
  data() {
    return {
      tabs: 'details'
    }
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    ...mapState([
      'section'
    ]),
    slug () {
      return this.$route.params.slug
    },
    canEditSection () {
      return this.$user.isAdmin || !!this.editors.find({ slug: this.$user.slug })
    }
  }
}
</script>
